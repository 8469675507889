import React, { useState } from 'react'
import '../style/PickAvatar.css'
import GameToken from '../style/imgs/game_token.png'

function PickAvatar({ avatar, isHaving, isIt, handle }) {
  const [style, setStyle] = useState(isIt)

  const isActiveStyle = () => {
    if (style) return
    if (handle(!isHaving)) setStyle(true)
  }

  return (
    <>
      <div id={style ? 'avatar_back_modal_active' : 'avatar_back_modal'}>
        <img id="avatar_pick_modal" src={avatar.image} alt="Аватарка"></img>

        <div id="avatar_cost">
          <label id="cost">{avatar.price}</label>
          <img src={GameToken} id="gametoken" alt="Геймтокен"></img>
        </div>

        {style ? (
          <button id="button_pick_modal_active" onClick={isActiveStyle}>
            Выбрано
          </button>
        ) : (
          <button id="button_pick_modal" onClick={isActiveStyle}>
            {isHaving ? 'Выбрать' : 'Купить'}
          </button>
        )}
        {/**  <button className={isActiveStyle ? 'button_pick_modal_active' : 'button_pick_modal'} onClick={isActiveStyle}>Выбрать</button>
         */}
      </div>
    </>
  )
}

export default PickAvatar
