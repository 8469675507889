import React, { useState } from 'react'
import '../style/TeacherHW.css'

function TeacherHW({ hw, update }) {
  let [isDownloading, setIsDownloading] = useState(false)

  async function handleDownload() {
    setIsDownloading(true)
    let blob = await fetch(hw.file_url).then(r => r.blob())
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement('a')
    a.href = url
    a.download = `${hw.file_url.split('/').pop()}`
    setIsDownloading(false)
    a.click()
  }

  return (
    <div id="teacher_hw_wrap">
      <div id="right_teacher_hw">
        <label>
          {hw.user.last_name} {hw.user.first_name}
        </label>
        <button className="underline_button" onClick={handleDownload}>
          {isDownloading ? 'Загрузка...' : 'Посмотреть д/з'}
        </button>
      </div>
      <div id="left_teacher_hw">
        {/* <label>Просмотрено</label>
            <input className='check_hw' type="checkbox" checked={isViewed} onChange={view}></input>
           <label>Принято</label>
            <input className='check_hw' type="checkbox" checked={isApproved} onChange={approve}></input>  */}
        <button id="accept_hw" onClick={update}>
          {hw.is_approved ? 'Отозвать' : 'Принять'}
        </button>
      </div>
    </div>
  )
}

export default TeacherHW
