import React, { useState } from 'react'
import '../style/CreateWork.css'
import Switch from '@mui/material/Switch'
// import Bubble from '../style/imgs/bubble_create_work.svg'

function CreateWork({ create }) {
  let newWork = {
    name: '',
    description: '',
    is_public: true,
  }
  let [work, setWork] = useState({ ...newWork })

  let [images, setImages] = useState([])
  let [sent, setSent] = useState(false)
  let [isLoading, setIsLoading] = useState(false)

  let createWork = async () => {
    if (isLoading) return
    setIsLoading(true)
    const formData = new FormData()
    images.forEach((image, index) => {
      formData.append(
        `images`,
        image,
        `${work.name}_${index + 1}.${image.name.split('.').pop()}`,
      )
    })
    formData.append(`name`, work.name)
    formData.append(`description`, work.description)
    formData.append(`is_public`, work.is_public)
    let resp = await create(formData)
    setSent(resp)
    setWork({ ...newWork })
    setImages([])
    setIsLoading(false)
  }

  function handleNameChange(event) {
    setWork({ ...work, name: event.target.value })
  }

  function handleDescriptionChange(event) {
    setWork({ ...work, description: event.target.value })
  }

  function handlePublicChange(event) {
    setWork({ ...work, is_public: event.target.checked })
  }

  function handleImagesChange(event) {
    setImages([...event.target.files])
  }

  return (
    <div id="wrapper_create">
      <div id="background_create">
        <div id="work_create">
          <input
            id="name_create_work"
            type="text"
            placeholder="Название работы"
            value={work.name}
            onChange={handleNameChange}></input>
          <textarea
            id="descritption_work"
            placeholder="Описание работы"
            rows="4"
            value={work.description}
            onChange={handleDescriptionChange}
          />
        </div>

        <div id="create_work">
          <div id="create_top">
            <label id="work_is_visible">Работа видна всем:</label>
            <Switch checked={work.is_public} onChange={handlePublicChange} />
          </div>
          <div id="create_bottom">
            <div className="wrap_input_pictures">
              <label id="file_upload" className="file-input">
                Добавить фото
                <input
                  id="file_upload"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImagesChange}
                  hidden
                />
              </label>
              <label id="under_button_sign">
                {images.length === 0
                  ? 'Не выбрано'
                  : 'Выбрано фото: ' + images.length}
              </label>
            </div>

            <div className="wrap_input_pictures">
              <button onClick={createWork} className="hw_create_buttons">
                {isLoading ? 'Загрузка...' : 'Опубликовать'}
              </button>
              <div
                style={
                  sent ? { visibility: 'visible' } : { visibility: 'hidden' }
                }>
                <label id="under_button_sign">Отправлено</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src={Bubble} id='bubble_create_work'></img> */}
    </div>
  )
}

export default CreateWork
