import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import '../style/Login.css'
import Pattern from '../style/imgs/pattern.jfif'
import Logo from '../style/imgs/logo.jfif'
import Logo1 from '../style/imgs/logo_cut.jpg'
import EyeClose from '../style/imgs/eye_close.png'
import EyeOpen from '../style/imgs/eye_open.png'

const LoginPage = () => {
  useEffect(() => {
    document.title = 'Вход'
  }, [])

  let { loginUser } = useContext(AuthContext)

  const [showPass, setShowPass] = useState(false)
  const togglePassword = () => {
    setShowPass(!showPass)
  }

  return (
    <div className="wrap">
      <div className="pattern">
        <img
          className="patternImg"
          src={Pattern}
          alt="паттерн"
          style={{ maxWidth: '100%' }}
        />
      </div>
      <Link className="underline_button" id="to_gallery" to="/gallery">
        Перейти в галерею
      </Link>
      <div className="workspace">
        <img className="logoImg" src={Logo1} alt="Логотип" />
        <h1 className="text">ЛИЧНЫЙ КАБИНЕТ</h1>
        <form className="form-signin" onSubmit={loginUser}>
          <input
            type="text"
            id="inputLogin"
            placeholder="Логин"
            name="username"
            required></input>
          <div id="pass_and_eye">
            <input
              type={showPass ? 'text' : 'password'}
              id="inputPass"
              placeholder="Пароль"
              name="password"
              required></input>
            <img
              id="eyes"
              src={showPass ? EyeOpen : EyeClose}
              onClick={togglePassword}
              alt={showPass ? 'Скрыть' : 'Показать'}></img>
          </div>
          <button id="button_login" type="submit">
            ВОЙТИ
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
