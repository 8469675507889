import React, { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

const AuthContext = createContext(null)

export default AuthContext

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()

  let [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(localStorage.getItem('authTokens'))
      : null,
  )
  let [isLoading, setIsLoading] = useState(false)

  let pathAfterLogin = role => {
    if (role === 'student') return '/account'
    if (role === 'teacher') return '/teacher'
    if (role === 'admin') return '/admin'
  }

  let loginUser = e => {
    e.preventDefault()
    if (isLoading) return
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/token/`, {
        username: e.target.username.value,
        password: e.target.password.value,
      })
      .then(response => {
        let decodedJWT = jwt_decode(response.data.access)
        setUser(decodedJWT)
        localStorage.setItem('authTokens', JSON.stringify(response.data))
        setIsLoading(false)
        navigate(pathAfterLogin(decodedJWT.status))
      })
      .catch(error => {
        setIsLoading(false)
        if (error?.response?.status === 401)
          alert('Проверьте правильность данных')
        else alert('Произошла непредвиденная ошибка')
        console.log(error)
      })
  }

  let refreshToken = async () => {
    const authTokens = JSON.parse(localStorage.getItem('authTokens'))

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/token/refresh/`,
        {
          refresh: authTokens?.refresh,
        },
      )

      if (!response.data?.access) {
        logoutUser()
      }

      setUser(jwt_decode(response.data.access))
      localStorage.setItem('authTokens', JSON.stringify(response.data))

      return response.data
    } catch (error) {
      logoutUser()
    }
  }

  let logoutUser = () => {
    setUser(null)
    localStorage.removeItem('authTokens')
  }

  let contextData = {
    user: user,
    setUser: setUser,
    refreshToken: refreshToken,
    loginUser: loginUser,
    logoutUser: logoutUser,
  }

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}
