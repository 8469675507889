import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const PrivateWrapper = ({ role }) => {
  let { user } = useContext(AuthContext)
  let isAllowed = !!user && (!role || user?.status === role)
  if (role === 'user' && !user) isAllowed = true
  let rolePath = '/'
  if (user?.status === 'admin') rolePath = '/admin'
  else if (user?.status === 'teacher') rolePath = '/teacher'
  else if (user?.status === 'student') rolePath = '/account'
  else rolePath = '/gallery'

  return isAllowed ? <Outlet /> : <Navigate to={rolePath} />
}

export default PrivateWrapper
