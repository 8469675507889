import React from 'react'
import GalleryItem from '../components/GalleryItem.js'
import CreateWork from '../components/CreateWork.js'
// import '../style/Gallery.css'
import '../style/MyGallery.css'

const MyGallery = ({ works, setWorks, create }) => {
  return (
    <div id="background_pattern">
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <CreateWork create={create} />
        <div id="back" style={{ background: 'transparent' }}>
          <div id="works">
            {works
              .map(work => (
                <GalleryItem
                  key={work.id}
                  workPar={work}
                  deleteMe={() => setWorks(works.filter(w => w.id !== work.id))}
                />
              ))
              .reverse()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyGallery
