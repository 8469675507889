import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import OpenBurgerButton from '../../style/imgs/open_burger_menu.svg'
import CloseBurgerButton from '../../style/imgs/close_burger_menu.svg'
import '../../style/NavBar.css'
import AuthContext from '../../context/AuthContext'

const Navbar = () => {
  let { user } = useContext(AuthContext)
  let location = useLocation()
  let { logoutUser } = useContext(AuthContext)

  const [isOpen, setIsOpen] = useState(false)
  const handleMenuClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  return (
    <>
      {location.pathname !== '/' && (
        <>
          <nav className="navbar">
            <div className="container">
              <button id="burger_button" onClick={handleMenuClick}>
                <img
                  id="style_burger_button"
                  src={isOpen ? CloseBurgerButton : OpenBurgerButton}
                  alt="Меню"></img>
              </button>

              <div className={`nav-elements  ${isOpen && 'active'}`}>
                <ul>
                  {!user && (
                    <li>
                      <NavLink to="/" activestyle="true">
                        {' '}
                        Вход{' '}
                      </NavLink>
                    </li>
                  )}

                  <li>
                    <NavLink to="/gallery" activestyle="true">
                      {' '}
                      Галерея{' '}
                    </NavLink>
                  </li>

                  {user?.status === 'admin' && (
                    <li>
                      <NavLink to="/admin" activestyle="true">
                        {' '}
                        Админка{' '}
                      </NavLink>
                    </li>
                  )}

                  {user?.status === 'student' && (
                    <li>
                      <NavLink to="/account" activestyle="true">
                        {' '}
                        Личный кабинет{' '}
                      </NavLink>
                    </li>
                  )}

                  {user?.status === 'teacher' && (
                    <li>
                      <NavLink to="/teacher" activestyle="true">
                        {' '}
                        Преподаватель{' '}
                      </NavLink>
                    </li>
                  )}

                  {!!user && (
                    <li>
                      <NavLink
                        onClick={logoutUser}
                        activestyle="true"
                        style={{ textDecoration: 'none' }}>
                        {' '}
                        Выход{' '}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </>
      )}
    </>
  )
}

export default Navbar
