import axios from 'axios'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'

const baseURL = process.env.REACT_APP_API_ENDPOINT

const useAxios = () => {
  const { refreshToken, logoutUser } = useContext(AuthContext)

  const axiosInstance = axios.create({
    baseURL,
  })

  axiosInstance.interceptors.request.use(
    async config => {
      const tokens = JSON.parse(localStorage.getItem('authTokens'))

      if (!config?.anon && tokens?.access) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${tokens?.access}`,
        }
      }

      return config
    },
    error => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const config = error?.config

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true

        const result = await refreshToken()
        if (result?.access) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result?.access}`,
          }
        }

        return axiosInstance(config)
      } else if (error?.response?.status === 401 && config?.sent) {
        alert('Войдите в аккаунт заного')
        logoutUser()
      } else if (error?.response?.data === 'Not enough tokens')
        alert('Не хватает геймтокенов')
      else if (error?.response?.data === 'Avatar has already been purchased')
        alert('Аватарка уже куплена')
      else if (error?.response?.data === 'Avatar not purchased')
        alert('У вас не приобретена эта аватарка')
      else if (error?.response?.status === 400)
        alert('Проверьте правильность данных')
      else alert('Произошла непредвиденная ошибка')
      console.log(error)
      return Promise.reject(error)
    },
  )

  return axiosInstance
}

export default useAxios
